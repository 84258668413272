import { Component, Input, Output, ViewChild, ElementRef, OnInit, SimpleChanges, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { SalesDateService } from 'src/app/components/elements/sales-date-filter/sales-date-filter.service';
import { CsvService } from 'src/app/services/csv.services';
import { RequestService } from 'src/app/services/request.service';
import { isAllSelected, faceshareSetQueryString } from 'src/app/helpers/utils';
import { FaceShareHelper } from 'src/app/components/pages/faceshare/faceshare.helper';
import { FaceshareQueries } from 'src/app/model/constants';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'faceshare-by-account',
  templateUrl: './by-account.component.html',
  styleUrls: ['./by-account.component.scss']
})

export class FaceShareByAccountComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lstOptionSelectedDateType: any;
  @Input() lstOptionSelectedSalesDate: any;

  @Output() filterReady = new EventEmitter<boolean>();
  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  private salesDateSubscription: Subscription;

  sectionName: string = 'By Account';
  lstOptionIsUsed: Record<string, boolean> = {
    CHANNEL: true
  };
  lstOptionSelected: Record<string, string[]> = {
    CHANNEL: ['SM']
  };
  lstOptionApplied: Record<string, string[]> = {
    CHANNEL: ['SM']
  };

  filters = { CHANNEL: 'CHANNEL' };
  categories: any[]   = [];
  chartData : any[]   = [];
  ttlObj: object      = {};
  isLoading : boolean = false;
  isHasFirstLoad: boolean = false;
  lstAllOptionsData: any = {};
  filterQuery: Record<string, any> = {};

  constructor(private requestService: RequestService,
              private salesDateService: SalesDateService,
              private csvService: CsvService,
              private mixPanelTrackingService: MixPanelTrackingService) {}

  ngOnInit(): void {
    this.categories = FaceShareHelper.getAccountCategories() || [];

    this.salesDateSubscription = this.salesDateService.getTraxSalesDate().subscribe((date: string[]) => {
      if (date && date.length > 0) {
        this.requestService.sendRequest(FaceshareQueries.byAccountFilter, {}, true).then((res) => {
          const data = JSON.parse(res) || {};

          this.lstAllOptionsData = {
            CHANNEL: data?.CHANNEL || []
          };

          this._handleonFilterReady();
        });
      }
    });

    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasDateTypeChange  = changes['lstOptionSelectedDateType'] && this.lstOptionSelectedDateType?.length > 0;
    const hasSalesDateChange = changes['lstOptionSelectedSalesDate'] && this.lstOptionSelectedSalesDate?.length > 0;

    if (hasDateTypeChange || hasSalesDateChange) {
      this._onSearchData();
    }

    this.isHasFirstLoad = true;
  }

  ngOnDestroy(): void {
    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  // Update Chart
  public onUpdateChart(event: any) {
    const { lstOption_Selected: lstOptionSelected } = event;

    if (!lstOptionSelected) {
      return;
    }

    if (this.lstOptionSelected) {
      this.lstOptionSelected = { ...lstOptionSelected };
      this.lstOptionApplied  = { ...lstOptionSelected };
      this._onSearchData();
    }
  }

  public downloadExcel() {
    this._getQueryObject();

    this.csvService.getCsvUrl(FaceshareQueries.byAccountCSV, this.filterQuery, '')
      .subscribe((data) => {
        const csvUrl = data?.Csv_Url;

        if (csvUrl) {
          this._downloadCsv(csvUrl);
        }
      });
  }

  public onSaveItemsChange(event: any) {}

  private _onSearchData() {
    if (!this.lstOptionSelectedDateType?.length || !this.lstOptionSelectedSalesDate?.length) {
      return;
    }

    this.isLoading = true;

    try {
      const code = FaceshareQueries.byAccount;
      this._getQueryObject();

      this.requestService.sendRequest(code, this.filterQuery, true)
        .then((res) => {
          const data = JSON.parse(res)?.ListAll || [];
          this.chartData = data;
          this.ttlObj    = data.filter(item => item.CUSTOMER_GROUP_NAME === 'SM TTL')[0] || {};
          this._handleonReady();
        })
        .catch((error) => {
          console.error(`Request failed: ${error}`);
        })
        .finally(() => {
          this.isLoading = false;
        })
    } catch (error) {
      console.error(`Error in _onSearchData: ${error}`);
      this.isLoading = false;
    }
  }

  public accShouldHighlight(categoryName: string, item: any): boolean {
    const isKoCategory    = categoryName === 'ALL_KO';
    const isSntCategory   = categoryName === 'ALL_SUNTORY';
    const koAverageValue  = this.ttlObj['ALL_KO'];
    const sntAverageValue = this.ttlObj['ALL_SUNTORY'];

    if (isKoCategory) {
      return item[categoryName] < koAverageValue;
    }

    if (isSntCategory) {
      return item[categoryName] > sntAverageValue;
    }

    return false;
  }

  public getAccValueDisplay(value: number, valueDisplay: string): string {
    const isNegative = value < 0;
    const isPositive = value > 0;

    if (isNegative && !valueDisplay.startsWith('-')) {
      return `-${valueDisplay}`;
    }

    if (isPositive && !valueDisplay.startsWith('+')) {
      return `+${valueDisplay}`;
    }

    return valueDisplay;
  }

  private _getQueryObject() {
    const queryObject = faceshareSetQueryString(this.lstOptionApplied) || {};
    const salesDate   = this.lstOptionSelectedSalesDate;
    const isSalesDateSelected = !isAllSelected(salesDate);
    const encodedSalesDate    = isSalesDateSelected ? encodeURIComponent(salesDate) : '';

    this.filterQuery = {
      ...queryObject,
      CacheKey: `SALES_YM=${encodedSalesDate}${queryObject['CacheKey']}`,
      Selected: {
        ...queryObject['Selected'],
        SALES_YM: isSalesDateSelected ? salesDate : [],
      }
    };
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

  private _handleonFilterReady() {
    let t = this;
    setTimeout(() => {
      t.filterReady.emit(true);
    });
  }

  private _handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }
}
