import { Component, OnInit, OnDestroy, Input, Output, SimpleChanges, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { SalesDateService } from 'src/app/components/elements/sales-date-filter/sales-date-filter.service';
import { CsvService } from 'src/app/services/csv.services';
import { RequestService } from 'src/app/services/request.service';
import { isAllSelected, skuSetQueryString, faceshareSetQueryString } from 'src/app/helpers/utils';
import { FaceShareHelper } from 'src/app/components/pages/faceshare/faceshare.helper';
import { FaceshareQueries } from 'src/app/model/constants';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'faceshare-by-marker-category',
  templateUrl: './by-marker-category.component.html',
  styleUrls: ['./by-marker-category.component.scss']
})

export class FaceShareByMarkerCategoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lstOptionSelectedDateType: any;
  @Input() lstOptionSelectedSalesDate: any;

  @Output() ready = new EventEmitter<boolean>();
  @Output() filterReady = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  private salesDateSubscription: Subscription;
  sectionName: string = 'By Maker x Category / By Area';
  markerTitle        = 'By Maker x Category';
  areaTitle          = 'By Area';
  activeTab: number  = 1;
  isLoading: boolean = false;
  isHasFirstLoad: boolean = false;
  lstAllOptionsData: any = [];
  filterQuery: Record<string, any> = {};

  lstOptionIsUsed: Record<string, Record<string, boolean>> = {
    MARKET_CATEGORY: { CHANNEL: true, SALES_LOCATION_TYPE_NAME_EN: true },
    AREA: { CHANNEL: true }
  };
  lstOptionSelected: Record<string, Record<string, string[]>> = {
    MARKET_CATEGORY: { CHANNEL: ['SMDD'], SALES_LOCATION_TYPE_NAME_EN: ['Teiban Total'] },
    AREA: { CHANNEL: ['SMDD'] }
  };
  lstOptionApplied: Record<string, Record<string, string[]>> = {
    MARKET_CATEGORY: { CHANNEL: ['SMDD'], SALES_LOCATION_TYPE_NAME_EN: ['Teiban Total'] },
    AREA: { CHANNEL: ['SMDD'] }
  };

  filters: Record<string, string>   = { CHANNEL: 'CHANNEL', SALES_LOCATION_TYPE_NAME_EN: 'SALES_LOCATION_TYPE_NAME_EN' };
  categories: Record<string, any[]> = { MARKET_CATEGORY: [], AREA: [] };
  chartData: Record<string, any[]>  = { MARKET_CATEGORY: [], AREA: [] };
  manufactureKey  = 'MANUFACTURER_EN';
  areaKey         = 'AREA';

  constructor(private requestService: RequestService,
              private salesDateService: SalesDateService,
              private csvService: CsvService,
              private mixPanelTrackingService: MixPanelTrackingService) {}

  ngOnInit(): void {
    this.categories['MARKET_CATEGORY'] = FaceShareHelper.getMarkerCategories();
    this.categories['AREA']            = FaceShareHelper.getAreaCategories();

    this.salesDateSubscription = this.salesDateService.getTraxSalesDate().subscribe((date: string[]) => {
      if (date && date.length > 0) {
        this.requestService.sendRequest(FaceshareQueries.byMakerAreaFilter, {}, true).then((res) => {
          const data = JSON.parse(res) || {};

          this.lstAllOptionsData = {
            CHANNEL    : data?.CHANNEL || [],
            SALES_LOCATION_TYPE_NAME_EN: data?.SALES_LOCATION_TYPE_NAME_EN || [],
            AREA       : data?.AREA || []
          }

          this._handleonFilterReady();
        });
      }
    });

    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasDateTypeChange  = changes['lstOptionSelectedDateType'] && this.lstOptionSelectedDateType?.length > 0;
    const hasSalesDateChange = changes['lstOptionSelectedSalesDate'] && this.lstOptionSelectedSalesDate?.length > 0;

    if (hasDateTypeChange || hasSalesDateChange) {
        this._onSearchData('MARKET_CATEGORY');
        this._onSearchData('AREA');
    }

    this.isHasFirstLoad = true;
  }

  ngOnDestroy(): void {
    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  // Update Chart
  public onUpdateChart(event: any, keyName: string) {
    const { lstOption_Selected: lstOptionSelected } = event;

    if (!lstOptionSelected || !keyName) {
      return;
    }

    if (this.lstOptionSelected[keyName]) {
      this.lstOptionSelected[keyName] = { ...lstOptionSelected };
      this.lstOptionApplied[keyName]  = { ...lstOptionSelected };
      this._onSearchData(keyName);
    }
  }

  public formatManufactureName(manufacture: string) {
    const mapping = {
      'others': 'OTHERS',
      'ko'    : 'KO'
    };

    const name = manufacture.toLowerCase();
    return mapping[name] || manufacture;
  }

  public getTypeDisplay(manufacture: string, type: string) {
    const defaultLabel = manufacture !== 'Ttl # of Face' ? 'vs. LY' : 'Imp. vs. LY';
    const displayMap = {
      'FACING_TM': manufacture !== 'Ttl # of Face' ? 'Share (pts)' : 'Importance',
    };

    return displayMap[type] || defaultLabel;
  }

  public getMarkerValueDisplay(item: any, categoryValue: string): string {
    const value            = item[categoryValue + '_Display'];
    const isNegative       = item[categoryValue] < 0;
    const isPositive       = item[categoryValue] > 0;
    const shouldPrefixDash = item['TYPE'] === 'FACING_LY' && (!['Ttl # of Face'].includes(item['MANUFACTURER_EN']) ||  !['ALL_CATEGORY'].includes(categoryValue));

    if (shouldPrefixDash && isNegative && !value.startsWith('-')) {
      return `-${value}`;
    }

    if (shouldPrefixDash && isPositive && !value.startsWith('+')) {
      return `+${value}`;
    }

    return value;
  }

  public getAreaValueDisplay(categoryName: string, value: number, valueDisplay: string): string {
    const isNegative = value < 0;
    const isPositive = value > 0;

    if (isNegative && !valueDisplay.startsWith('-') && categoryName.endsWith('DIFF_VS_LY')) {
      return `-${valueDisplay}`;
    }

    if (isPositive && !valueDisplay.startsWith('+') && categoryName.endsWith('DIFF_VS_LY')) {
      return `+${valueDisplay}`;
    }

    return valueDisplay;
  }

  public getMarkerImgUrl(categoryValue: string) {
    return categoryValue !== 'KO' ? `${categoryValue}.png` : 'Coca-Cola.png';
  }

  public markerShouldHighlight(item: any, categoryValue: string): boolean {
    const isNegative     = item[categoryValue] < 0;
    const isTtlOfFaceAll = item['MANUFACTURER_EN'] === 'Ttl # of Face' && categoryValue === 'ALL_CATEGORY';
    const isFacingLY     = item['TYPE'] === 'FACING_LY';

    return isFacingLY && !isTtlOfFaceAll && isNegative;
  }

  public areaShouldHighlight(item: any, categoryValue: string) {
    console.log(item, categoryValue);
  }

  // Download Excel
  public downloadExcel() {
    this._getQueryObject();

    this.csvService.getCsvUrl(FaceshareQueries.byMakerAreaCSV, this.filterQuery, '')
      .subscribe((data) => {
        const csvUrl = data?.Csv_Url;

        if (csvUrl) {
          this._downloadCsv(csvUrl);
        }
      });
  }

  // Helper
  private _onSearchData(keyName: string) {
    if (!this.lstOptionSelectedDateType?.length || !this.lstOptionSelectedSalesDate?.length) {
      return;
    }

    this.isLoading = true;

    try {
      const code = this._getCode(keyName, false);
      this._getQueryObject(keyName);

      this.requestService.sendRequest(code, this.filterQuery, true)
        .then((res) => {
          const data = JSON.parse(res)?.ListAll || [];

          this.chartData[keyName] = (keyName === 'MARKET_CATEGORY')
            ? this._markerGroupByAttribute(data, this.manufactureKey)
            : this._areaGroupByAttribute(data);

          this._handleonReady();
        })
        .catch((error) => {
          console.error(`Request failed: ${error}`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } catch (error) {
      console.error(`Error in _onSearchData: ${error}`);
      this.isLoading = false;
    }
  }

  private _markerGroupByAttribute = (data: any, attributeName: string) => {
    const grouped = data.reduce((acc, item) => {
      const key = item[attributeName];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const sortOrder = ['Ttl # of Face', 'KO', 'Suntory', 'Kirin', 'Asahi', 'Itoen', 'Others'];

    return sortOrder
      .map(key => {
        const group = grouped[key];
        if (group) {
          group.sort((a, b) => {
            if (a.MANUFACTURER_EN === b.MANUFACTURER_EN) {
              if (a.MANUFACTURER_EN === 'Ttl # of Face') {
                return a.TYPE === 'FACING_LY' && b.TYPE !== 'FACING_LY' ? -1 : 1;
              } else {
                return a.TYPE === 'FACING_LY' && b.TYPE !== 'FACING_LY' ? 1 : -1;
              }
            }

            return 0;
          });
          group[0].row_span = group.length;
          return group;
        }

        return [];
      })
      .reduce((acc, val) => acc.concat(val), []);
  };

  private _areaGroupByAttribute = (data) => {
    const sortOrder = ['CCBJI', 'North Kanto/South Tohoku', 'Metropolitan', 'Kansai/Chubu', 'Kyushu/Chushikoku'];

    const result = data.reduce((acc, item) => {
      const group = acc.find(g => g.SALES_DISTRICT_GROUP === item.SALES_DISTRICT_GROUP);

      const renameKey = (key, type) => {
        const suffix = key.endsWith('_Display') ? '_Display' : '';
        const baseKey = key.replace('_Display', '');
        return type === 'FACING_TM'
          ? `${baseKey}_SHARE${suffix}`
          : `${baseKey}_DIFF_VS_LY${suffix}`;
      };

      if (!group) {
        const newItem = {
          SALES_DISTRICT_GROUP: item.SALES_DISTRICT_GROUP,
        };
        Object.keys(item).forEach(key => {
          if (key !== 'SALES_DISTRICT_GROUP' && key !== 'TYPE' && key !== 'STORE_TM' && key !== 'STORE_TM_Display') {
            const newKey = renameKey(key, item.TYPE);
            newItem[newKey] = item[key];
          } else if (key === 'STORE_TM' || key === 'STORE_TM_Display') {
            newItem[key] = item[key];
          }
        });
        acc.push(newItem);
      } else {
        Object.keys(item).forEach(key => {
          if (key !== 'SALES_DISTRICT_GROUP' && key !== 'TYPE' && key !== 'STORE_TM' && key !== 'STORE_TM_Display') {
            const newKey = renameKey(key, item.TYPE);
            group[newKey] = item[key];
          } else if (key === 'STORE_TM' || key === 'STORE_TM_Display') {
            group[key] = item[key];
          }
        });
      }

      return acc;
    }, []);

    // Sort the result based on the predefined sortOrder
    result.sort((a, b) => sortOrder.indexOf(a.SALES_DISTRICT_GROUP) - sortOrder.indexOf(b.SALES_DISTRICT_GROUP));
    return result;
  };


  private _getCode(keyName: string, isExport: boolean) {
    const queryType = keyName === 'MARKET_CATEGORY' ? 'byMarker' : 'byArea';
    return isExport ? FaceshareQueries[`${queryType}CSV`] : FaceshareQueries[queryType];
  }

  private _getQueryObject(keyName?: string) {
    const queryObject = (keyName?.length && keyName === 'MARKET_CATEGORY') ? faceshareSetQueryString(this.lstOptionApplied[keyName]) : skuSetQueryString(this.lstOptionApplied[keyName]);
    const salesDate   = this.lstOptionSelectedSalesDate;
    const isSalesDateSelected = !isAllSelected(salesDate);
    const encodedSalesDate    = isSalesDateSelected ? encodeURIComponent(salesDate) : '';

    this.filterQuery = {
      ...queryObject,
      CacheKey: `SALES_YM=${encodedSalesDate}${queryObject['CacheKey']}`,
      Selected: {
        ...queryObject['Selected'],
        SALES_YM: isSalesDateSelected ? salesDate : [],
      }
    };
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

  private _handleonFilterReady() {
    let t = this;
    setTimeout(() => {
      t.filterReady.emit(true);
    });
  }

  private _handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  // Mixpanel Tracking
  public onMixpanelTrackingTabChange(e: any) {
    const { activeId } = e;
    const tabName      = activeId === 1 ? this.areaTitle : this.markerTitle;

    this.mixPanelTrackingService.onTrackingTabChange(this.sectionName, tabName);
  }
}
