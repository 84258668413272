import { Component, Renderer2, OnChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef, SimpleChanges, OnInit, HostListener } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Router } from '@angular/router';

import { DistrictRouterProgressHelper } from 'src/app/components/pages/district-route-progress/district_route_progress_helper';
import { getSortColumnName } from 'src/app/helpers/sort';
import { downloadAsPDF as _downloadAsPDF } from '../../../../helpers/pdfExtend';
import { FontStyle, PDFProperty } from 'src/app/model/pdfProperty.model';
import { PageModel  } from '../../../../model/page.model';
import { FILTER_CONFIGS, AnalyticsQueries } from 'src/app/model/constants';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { PRIORITY_ORDER } from 'src/app/helpers/sort';
import { sortArrayByPriority } from 'src/app/helpers/sort';
import { CsvService } from 'src/app/services/csv.services';
import { UserService } from 'src/app/services/user.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'progress-by-route',
  templateUrl: './progress-by-route.component.html',
  styleUrls: ['./progress-by-route.component.scss']
})
export class ProgressByRouteComponent implements OnChanges, OnInit {
  @Input() saleYearMonth : any[];

  sectionName: string = 'Progress by Base/Route';
  progressbyRouteData: any;
  listColumn: any;
  sortType : string      = "desc";
  sortColumn: string     = "NSR_DIFF_VS_BP";
  sortColumnName: string = '';

  //filter
  @Input() lstAllOptionsData: any;
  lstAllOptionsFilteredData: any;

  FILTERS: any                  = []
  DISTRICT_PROGRESS_FILTERS     = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.DISTRICT_PROGRESS_BY_ROUTE;
  defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT ?? 4;

  listData_Filter: any            = null;
  lstOption_States: any           = {};
  lstOption_States_Applied: any   = {};
  lstOption_Selected: any         = {};
  lstOption_IsUsed: any           = {};
  lstOption_Selected_Applied: any = {};

  ListRoot1_Data_CurrentPage: any;

  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.configurePagination();
  }

  isLoading: boolean = true;

  paging : PageModel = {
    current : 1,
    totalItem : 0,
    maxSize : 10,
    sizeOnPage: undefined,
    hideFirstLast: false
  };
  isHasFistLoad: boolean = false;

  constructor(private router: Router, public datepipe: DatePipe, private _renderer2: Renderer2,
	private csvService: CsvService, private mixPanelTrackingService: MixPanelTrackingService, private userService: UserService,
    private http: HttpClient) { }

    ngOnInit(): void {
      this._getFilterConfigs();
      this.listColumn = DistrictRouterProgressHelper.ListColumnByRoute();
      this.sortColumnName = getSortColumnName('PROGRESS_BY_DISTRICT', this.sortColumn);
      this.configurePagination();
      this.mixPanelTrackingService.observeElement(this.observedElement);
    }

    ngOnChanges(changes: SimpleChanges) {
      if(changes['saleYearMonth'] && this.saleYearMonth && this.saleYearMonth.length > 0){
        if(this.isHasFistLoad){
          this.onSearchData();
        } 
      }
      this.isHasFistLoad = true;
    }

  onInitData() {
    if (this.progressbyRouteData) {
      this.paging.totalPage = this.progressbyRouteData?.TotalPage;
      this.handleMarket();
    }
  }

  private _getFilterConfigs() {
    this.FILTERS    = FilterHelper.sortObjectByVisibleItems(FILTER_CONFIGS?.ADD_MORE_FILTER?.DEFAULT, this.DISTRICT_PROGRESS_FILTERS.slice(0, this.defaultVisibleFilters)) || [];
    const lstOption                 = FilterHelper.init_lstOption_States(`ProgressByRouteComponent_${this.userService.getUserFilterName()}`, this.FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
    this.lstOption_States   = {...lstOption?.lstOption_States};
    this.lstOption_Selected = {...lstOption?.lstOption_Selected};
    this.listData_Filter    = {...lstOption.listData_Filter};
    this.lstOption_States_Applied = {...lstOption?.lstOption_States};
    this.lstOption_Selected_Applied = {...lstOption?.lstOption_Selected};
    this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.DISTRICT_PROGRESS_FILTERS) || {};
  }

  private configurePagination(): void {
    const winWidth = window.innerWidth;

    if (winWidth > 1024) {
      this.paging.maxSize = 10
    }

    if (1024 >= winWidth && winWidth >= 768) {
      this.paging.maxSize = 7
    }

    if (winWidth < 768) {
      this.paging.maxSize = 3;
    }
  }

  onPageChange(newPage: number): void {
    this.paging.current = newPage;

    this.onSearchData();
  }

  onTrackingPaging(newPage: number): void {
    this.mixPanelTrackingService.onTrackingPagingChange(this.sectionName, newPage);
  }

  sortTable(defaultSort: string,column: string) {
    if(column == this.sortColumn){
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    }else{
      this.sortType = "desc";
    }
    this.sortColumn = column;
    this.paging.current = 1;
    this.sortColumnName = getSortColumnName('PROGRESS_BY_ROUTE', this.sortColumn);

    this.onSearchData();
  }

  formartNumber(category: string, number: any) {
    return DistrictRouterProgressHelper.FormartNumber(category, number);
  }

  formartClassForColumn(isLunisolarColor: boolean, value: any) {
    return DistrictRouterProgressHelper.formartClassForColumn(isLunisolarColor, value);
  }

  filteredRoots2(listRoot2: any,nameRoot1: string) {
    return listRoot2.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1 );
  }

  filteredRoots3(listRoot3: any,nameRoot1: string, nameRoot2 : string) {
    return listRoot3.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1  && p["SALES_HQ_LEV2_NAME"] === nameRoot2);
  }

  filteredRoots4(listAll: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string) {
    return listAll.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1 && p["SALES_HQ_LEV2_NAME"] === nameRoot2 && p["SALES_HQ_LEV3_NAME"] ===nameRoot3 );
  }

  filteredRoots5(listAll: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string , nameRoot4 : string) {
    return listAll.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1 && p["SALES_HQ_LEV2_NAME"] === nameRoot2 && p["SALES_HQ_LEV3_NAME"] ===nameRoot3 && p["SALES_CENTER_NAME"] ===nameRoot4 );
  }

  filteredRoots6(listAll: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string , nameRoot4 : string , nameRoot5 : string) {
    return listAll.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1 && p["SALES_HQ_LEV2_NAME"] === nameRoot2 && p["SALES_HQ_LEV3_NAME"] ===nameRoot3 && p["SALES_CENTER_NAME"] ===nameRoot4 && p["SALES_CONTROL_BASE_NAME"] ===nameRoot5 );
  }

  handleMarket() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  onSearchData() {
    if (!this.saleYearMonth || this.saleYearMonth?.length === 0) {
      return;
    }

    this.isLoading = true;
    const analyticsQuery = DistrictRouterProgressHelper.RouteSetQueryString(
      {
        ...this.lstOption_Selected_Applied,
        SALES_DATE: this.saleYearMonth
      },
      this.paging.current,
      this.sortType,
      this.sortColumn,
      false
    );

    DistrictRouterProgressHelper.GetProgressbyRouteAggregateKey(this.http, analyticsQuery)
      .then((data) => {
        this.progressbyRouteData = data;
        this.onInitData();
        this.isLoading = false;
    });
  }

  public updateProgressByRouteData(event: any) :void {
    const { lstOption_Selected, lstOption_States } = event;

    this.lstOption_Selected         = { ...lstOption_Selected };
    this.lstOption_Selected_Applied = { ...lstOption_Selected };
    this.lstOption_States_Applied   = { ...lstOption_States }


    this.paging.current = 1;
    this.onSearchData();
  }

  onResetFiltersGroup($event: any) {
    const {data} = $event;

    if (data && Array.isArray(data) && data.length > 0) {
      data.map(key => {
        if (key && key.length > 0) {
          this.lstOption_Selected[key] = ['All'];
        }
      });
    }
  }

  // Export data to CSV | PDF
  export_CSV(){
    const analyticsQuery= DistrictRouterProgressHelper.RouteSetQueryString(
      {
        ...this.lstOption_Selected_Applied,
        SALES_DATE: this.saleYearMonth
      },
      this.paging.current,
      this.sortType,
      this.sortColumn,
      false
    );

    DistrictRouterProgressHelper.GetProgressbyRouteAggregateKey(this.http, analyticsQuery,true)
      .then((data) => {
        this.renderCSV(data,'Progress by Route');
    });
  }

  export_CSV_by_Url() {
    const analyticsQuery= DistrictRouterProgressHelper.RouteSetQueryString(
      {
        ...this.lstOption_Selected_Applied,
        SALES_DATE: this.saleYearMonth
      },
      this.paging.current,
      this.sortType,
      this.sortColumn,
      false
    );

    this.csvService.getCsvUrl(AnalyticsQueries.progressByRoute, analyticsQuery, '')
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  exportAll_CSV(){
    const analyticsQuery= DistrictRouterProgressHelper.RouteSetQueryString(
      {
        ...this.lstOption_Selected_Applied,
        SALES_DATE: this.saleYearMonth
      },
      1,
      this.sortType,
      this.sortColumn,
      true
    );

    DistrictRouterProgressHelper.GetProgressbyRouteAggregateKey(this.http, analyticsQuery,true)
      .then((data) => {
        this.renderCSV(data,'Progress by Route All pages CSV');
    });
  }

  exportAll_CSV_by_Url() {
    const analyticsQuery= DistrictRouterProgressHelper.RouteSetQueryString(
      {
        ...this.lstOption_Selected_Applied,
        SALES_DATE: this.saleYearMonth
      },
      1,
      this.sortType,
      this.sortColumn,
      true
    );

    this.csvService.getCsvUrl(AnalyticsQueries.progressByRoute, analyticsQuery, '')
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  renderCSV(listRoot : any, fileName: string){
    let data = [];
    let filters:any;
    filters = {
      saleYearMonth:this.saleYearMonth.toString(),
      saleHQLev1: this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME,
      saleHQLev2: this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME,
      saleHQLev3: this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME,
      saleCenter: this.lstOption_Selected_Applied.SALES_CENTER_NAME,
      saleBaseControl: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_NAME,
      saleRoute: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_ROUTE_CODE,
      channelLev1: this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME,
      channelLev2: this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME,
      channelLev3: this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME,
      channel_NL : this.lstOption_Selected_Applied.CHANNEL_NL
    };


    let SALES_HQ_LEV1_NAME = "";
    let SALES_HQ_LEV2_NAME = "";
    let SALES_HQ_LEV3_NAME = "";
    let SALES_CENTER_NAME = "";
    let SALES_CONTROL_BASE_NAME = "";
    //let SALES_CONTROL_BASE_ROUTE_CODE = "";

    let SALES_HQ_LEV1_CODE = "";
    let SALES_HQ_LEV2_CODE = "";
    let SALES_HQ_LEV3_CODE = "";
    let SALES_CENTER_CODE = "";
    let SALES_CONTROL_BASE_CODE = "";
    //let SALES_CONTROL_BASE_ROUTE_CODE_CODE = "";

    const root1Sort = sortArrayByPriority([...listRoot.ListRoot1], PRIORITY_ORDER?.SALES_HQ_LEV1_NAME, 'SALES_HQ_LEV1_NAME');
            root1Sort.forEach(root1=>{

      data.push(this.toRowCSV(root1, SALES_HQ_LEV1_NAME, "Total", "","","", SALES_HQ_LEV1_CODE, "Total", "","" ,"", filters));
      SALES_HQ_LEV1_CODE = root1.SALES_HQ_LEV1_CODE ===''?SALES_HQ_LEV1_CODE:root1.SALES_HQ_LEV1_CODE;
      SALES_HQ_LEV1_NAME = root1.SALES_HQ_LEV1_NAME ===''?SALES_HQ_LEV1_NAME:root1.SALES_HQ_LEV1_NAME;

      const root2Sort = sortArrayByPriority(this.filteredRoots2(listRoot.ListRoot2,root1['SALES_HQ_LEV1_NAME']), PRIORITY_ORDER?.SALES_HQ_LEV2_NAME, 'SALES_HQ_LEV2_NAME');
      root2Sort.forEach(root2=>{
        data.push(this.toRowCSV(root2, SALES_HQ_LEV1_NAME, SALES_HQ_LEV2_NAME, "Total","","",SALES_HQ_LEV1_CODE, SALES_HQ_LEV2_CODE, "Total","","", filters));
        SALES_HQ_LEV2_CODE = root2.SALES_HQ_LEV2_CODE ===''?SALES_HQ_LEV2_CODE:root2.SALES_HQ_LEV2_CODE;
        SALES_HQ_LEV2_NAME = root2.SALES_HQ_LEV2_NAME ===''?SALES_HQ_LEV2_NAME:root2.SALES_HQ_LEV2_NAME;

        const root3Sort = sortArrayByPriority(this.filteredRoots3(listRoot.ListRoot3,root1['SALES_HQ_LEV1_NAME'],root2['SALES_HQ_LEV2_NAME']), PRIORITY_ORDER?.SALES_HQ_LEV3_NAME, 'SALES_HQ_LEV3_NAME');
        root3Sort.forEach(root3=>{
          data.push(this.toRowCSV(root3, SALES_HQ_LEV1_NAME, SALES_HQ_LEV2_NAME, SALES_HQ_LEV3_NAME,"Total","",SALES_HQ_LEV1_CODE, SALES_HQ_LEV2_CODE, SALES_HQ_LEV3_CODE,"Total","", filters));
                SALES_HQ_LEV3_CODE = root3.SALES_HQ_LEV3_CODE ===''?SALES_HQ_LEV3_CODE:root3.SALES_HQ_LEV3_CODE;
                SALES_HQ_LEV3_NAME = root3.SALES_HQ_LEV3_NAME ===''?SALES_HQ_LEV3_NAME:root3.SALES_HQ_LEV3_NAME;

          this.filteredRoots4(listRoot.ListRoot4,root1['SALES_HQ_LEV1_NAME'],root2['SALES_HQ_LEV2_NAME'],root3['SALES_HQ_LEV3_NAME']).forEach(root4=>{
                   data.push(this.toRowCSV(root4, SALES_HQ_LEV1_NAME, SALES_HQ_LEV2_NAME, SALES_HQ_LEV3_NAME, SALES_CENTER_NAME, "Total",SALES_HQ_LEV1_CODE, SALES_HQ_LEV2_CODE, SALES_HQ_LEV3_CODE,SALES_CENTER_CODE,"Total", filters));
                   SALES_CENTER_CODE = root4.SALES_CENTER_CODE ===''?SALES_CENTER_CODE:root4.SALES_CENTER_CODE;
                   SALES_CENTER_NAME = root4.SALES_CENTER_NAME ===''?SALES_CENTER_NAME:root4.SALES_CENTER_NAME;

                   this.filteredRoots5(listRoot.ListRoot5, root1['SALES_HQ_LEV1_NAME'], root2['SALES_HQ_LEV2_NAME'] , root3['SALES_HQ_LEV3_NAME'] , root4['SALES_CENTER_NAME']).forEach(root5 => {
                    data.push(this.toRowCSV(root5, SALES_HQ_LEV1_NAME, SALES_HQ_LEV2_NAME, SALES_HQ_LEV3_NAME, SALES_CENTER_NAME, SALES_CONTROL_BASE_NAME,SALES_HQ_LEV1_CODE, SALES_HQ_LEV2_CODE, SALES_HQ_LEV3_CODE,SALES_CENTER_CODE,SALES_CONTROL_BASE_CODE, filters));

                    SALES_CONTROL_BASE_CODE = root5.SALES_CONTROL_BASE_CODE ===''?SALES_CONTROL_BASE_CODE:root5.SALES_CONTROL_BASE_CODE;
                    SALES_CONTROL_BASE_NAME = root5.SALES_CONTROL_BASE_NAME ===''?SALES_CONTROL_BASE_NAME:root5.SALES_CONTROL_BASE_NAME;

                    this.filteredRoots6(listRoot.ListAll,root1['SALES_HQ_LEV1_NAME'], root2['SALES_HQ_LEV2_NAME'] , root3['SALES_HQ_LEV3_NAME'] , root4['SALES_CENTER_NAME'] , root5['SALES_CONTROL_BASE_NAME']  ).forEach(root6 => {
                      data.push(this.toRowCSV(root6, SALES_HQ_LEV1_NAME, SALES_HQ_LEV2_NAME, SALES_HQ_LEV3_NAME, SALES_CENTER_NAME, SALES_CONTROL_BASE_NAME,SALES_HQ_LEV1_CODE, SALES_HQ_LEV2_CODE, SALES_HQ_LEV3_CODE,SALES_CENTER_CODE,SALES_CONTROL_BASE_CODE, filters));
                    });
                  });
          })
        })
      });
    });

    new AngularCsv(data, fileName, {
      showLabels: true,
      headers: ['Sales Year Month',
      'Sales HQ Lev1', 
      'Sales HQ Lev2', 
      'Sales HQ Lev3', 
      'Sales Center', 
      'Sale Control Base', 
      'Sale Route' , 
      'Channel Lev1', 
      'Channel Lev2', 
      'Channel Lev3',
      'National/Local',

      'Sales HQ Lev1 Code',
      'Sales HQ Lev1 Name',

      'Sales HQ Lev2 Code',
      'Sales HQ Lev2 Name',

      'Sales HQ Lev3 Code',
      'Sales HQ Lev3 Name',

      'Sales Center Code',
      'Sales Center Name',

      'Sales Control Base Code',
      'Sales Control Base Name',

      'Sales Control Base Route Code',


      'BAPC TY',
      'BAPC BP', //add
      'BAPC% VS BP', 
      'BAPC DIFF VS BP', 
      'BAPC LY', // add
      'BAPC% VS LY', 
      'BAPC DIFF VS LY', // add

      'NSR TY', 
      'NSR BP', // add
      'NSR% VS BP', 
      'NSR DIFF VS BP', 
      'NSR LY', // add
      'NSR% VS LY', 
      'NSR DIFF VS LY', // add

      'NSR PER CASE TY', 
      'NSR PER CASE BP',  // add
      'NSR PER CASE% VS BP',  // add
      'NSR PER CASE DIFF VS BP', 
      'NSR PER CASE LY', // add
      'NSR PER CASE% VS LY', // add
      'NSR PER CASE DIFF VS LY', 

      'GP TY', 
      'GP BP', // add
      'GP% VS BP', 
      'GP DIFF VS BP', 
      'GP LY', // add
      'GP% VS LY', 
      'GP DIFF VS LY', // add

      'GP PER CASE TY', 
      'GP PER CASE BP', // add
      'GP PER CASE% VS BP', //add 
      'GP PER CASE DIFF VS BP', 
      'GP PER CASE LY', //add 
      'GP PER CASE% VS LY',//add 
      'GP PER CASE DIFF VS LY'],
    });
  }

  toRowCSV(row:any, SALES_HQ_LEV1_NAME:any,SALES_HQ_LEV2_NAME:any,SALES_HQ_LEV3_NAME:any, SALES_CENTER_NAME:any, SALES_CONTROL_BASE_NAME: any, SALES_HQ_LEV1_CODE:any,SALES_HQ_LEV2_CODE:any,SALES_HQ_LEV3_CODE:any, SALES_CENTER_CODE:any, SALES_CONTROL_BASE_CODE: any, filters:any){
    return {
      SALE_YEAR_MONTH: filters === null?'': filters.saleYearMonth,
      SALE_HQ_LEV1: filters === null?'': String(filters.saleHQLev1),
      SALE_HQ_LEV2: filters === null?'': String(filters.saleHQLev2),
      SALE_HQ_LEV3: filters === null?'': String(filters.saleHQLev3),
      SALE_CENTER: filters === null?'': String(filters.saleCenter),
      SALE_CONTROLL_BASE: filters === null?'': String(filters.saleBaseControl),
      SALE_ROUTE: filters === null?'': String(filters.saleRoute).replace(/,/g, ', '),
      CHANNEL_LEV1: filters === null?'':String(filters.channelLev1),
      CHANNEL_LEV2: filters === null?'':String(filters.channelLev2),
      CHANNEL_LEV3: filters === null?'':String(filters.channelLev3),
      CHANNEL_NL : filters == null? '' : String(filters.channel_NL),

      SALES_HQ_LEV1_CODE : !row['SALES_HQ_LEV1_NAME_Display'] ? SALES_HQ_LEV1_CODE : row['SALES_HQ_LEV1_CODE'],
      SALES_HQ_LEV1_NAME : !row['SALES_HQ_LEV1_NAME_Display'] ? SALES_HQ_LEV1_NAME : row['SALES_HQ_LEV1_NAME_Display'], //

      SALES_HQ_LEV2_CODE : !row['SALES_HQ_LEV2_NAME_Display'] ? SALES_HQ_LEV2_CODE : row['SALES_HQ_LEV2_CODE'],
      SALES_HQ_LEV2_NAME : !row['SALES_HQ_LEV2_NAME_Display'] ? SALES_HQ_LEV2_NAME : row['SALES_HQ_LEV2_NAME_Display'], //

      SALES_HQ_LEV3_CODE : !row['SALES_HQ_LEV3_NAME_Display'] ? SALES_HQ_LEV3_CODE : row['SALES_HQ_LEV3_CODE'],
      SALES_HQ_LEV3_NAME : !row['SALES_HQ_LEV3_NAME_Display'] ? SALES_HQ_LEV3_NAME : row['SALES_HQ_LEV3_NAME_Display'], //

      SALES_CENTER_CODE : !row['SALES_CENTER_NAME_Display'] ? SALES_CENTER_CODE : row['SALES_CENTER_CODE'],
      SALES_CENTER_NAME : !row['SALES_CENTER_NAME_Display'] ? SALES_CENTER_NAME : row['SALES_CENTER_NAME_Display'], //

      SALES_CONTROL_BASE_CODE : !row['SALES_CONTROL_BASE_NAME_Display'] ? SALES_CONTROL_BASE_CODE : row['SALES_CONTROL_BASE_CODE'],
      SALES_CONTROL_BASE_NAME : !row['SALES_CONTROL_BASE_NAME_Display'] ? SALES_CONTROL_BASE_NAME : row['SALES_CONTROL_BASE_NAME_Display'], //

      SALES_CONTROL_BASE_ROUTE_CODE : !row['SALES_CONTROL_BASE_ROUTE_CODE_Display'] ? '' : row['SALES_CONTROL_BASE_ROUTE_CODE_Display'], //

      "BAPC_TY": row['BAPC_TY_Display'] ,
      "BAPC_BP": row['BAPC_BP_Display'] ,
      "BAPC_VS_BP": row['BAPC_VS_BP_Display'] ,
      "BAPC_DIFF_VS_BP": row['BAPC_DIFF_VS_BP_Display'] ,
      "BAPC_LY": row['BAPC_LY_Display'] ,
      "BAPC_VS_LY": row['BAPC_VS_LY_Display'] ,
      "BAPC_DIFF_VS_LY": row['BAPC_DIFF_VS_LY_Display'] ,


      "NSR_TY": row['NSR_TY_Display'] ,
      "NSR_BP": row['NSR_BP_Display'] ,
      "NSR_VS_BP": row['NSR_VS_BP_Display'] ,
      "NSR_DIFF_VS_BP": row['NSR_DIFF_VS_BP_Display'] ,
      "NSR_LY": row['NSR_LY_Display'] ,
      "NSR_VS_LY": row['NSR_VS_LY_Display'] ,
      "NSR_DIFF_VS_LY": row['NSR_DIFF_VS_LY_Display'] ,

      "NSR_PER_CASE_TY": row['NSR_PER_CASE_TY_Display'] ,
      "NSR_PER_CASE_BP": row['NSR_PER_CASE_BP_Display'] ,
      "NSR_PER_CASE_VS_BP": row['NSR_PER_CASE_VS_BP_Display'] ,
      "NSR_PER_CASE_DIFF_VS_BP": row['NSR_PER_CASE_DIFF_VS_BP_Display'] ,
      "NSR_PER_CASE_LY": row['NSR_PER_CASE_LY_Display'] ,
      "NSR_PER_CASE_VS_LY": row['NSR_PER_CASE_VS_LY_Display'] ,
      "NSR_PER_CASE_DIFF_VS_LY": row['NSR_PER_CASE_DIFF_VS_LY_Display'] ,

      "GP_TY": row['GP_TY_Display'] ,
      "GP_BP": row['GP_BP_Display'] ,
      "GP_VS_BP": row['GP_VS_BP_Display'] ,
      "GP_DIFF_VS_BP": row['GP_DIFF_VS_BP_Display'] ,
      "GP_LY": row['GP_LY_Display'] ,
      "GP_VS_LY": row['GP_VS_LY_Display'] ,
      "GP_DIFF_VS_LY": row['GP_DIFF_VS_LY_Display'] ,

      "GP_PER_CASE_TY": row['GP_PER_CASE_TY_Display'] ,
      "GP_PER_CASE_BP": row['GP_PER_CASE_BP_Display'] ,
      "GP_PER_CASE_VS_BP": row['GP_PER_CASE_VS_BP_Display'] ,
      "GP_PER_CASE_DIFF_VS_BP": row['GP_PER_CASE_DIFF_VS_BP_Display'] ,
      "GP_PER_CASE_LY": row['GP_PER_CASE_LY_Display'] ,
      "GP_PER_CASE_VS_LY": row['GP_PER_CASE_VS_LY_Display'] ,
      "GP_PER_CASE_DIFF_VS_LY": row['GP_PER_CASE_DIFF_VS_LY_Display'] ,
    }
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }


  @ViewChild('pdf_print_route', { static: false }) pdf_print_route: ElementRef;
  exportHQRoute_PDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing-se pdf-printing");

    const fontStyle:FontStyle={
      fontName:'IPAMinchoRegular',
      path:'../../assets/fonts/IPAMinchoRegular.ttf'
    }

    const pdfProperty:PDFProperty={
      fontSize : 20,
      labelColor:'#818EA5',
      valueColor:'#333',
      fontStyle: fontStyle
    }

    return _downloadAsPDF(name, this.pdf_print_route.nativeElement,false, pdfProperty );
  }
}
