import { Component, OnInit, OnChanges, Input, Output, SimpleChanges, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SalesDateService } from 'src/app/components/elements/sales-date-filter/sales-date-filter.service';
import { CsvService } from 'src/app/services/csv.services';
import { RequestService } from 'src/app/services/request.service';
import { isAllSelected, skuSetQueryString } from 'src/app/helpers/utils';
import { sortArrayByPriority } from 'src/app/helpers/sort';
import { PocHelper } from 'src/app/components/pages/poc/poc.helper';
import { PocQueries } from 'src/app/model/constants';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'poc-by-category',
  templateUrl: './by-category.component.html',
  styleUrls: ['./by-category.component.scss']
})

export class PocByCategoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lstOptionSelectedDateType: any;
  @Input() lstOptionSelectedSalesDate: any;

  @Output() ready = new EventEmitter<boolean>();
  @Output() filterReady = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  private salesDateSubscription: Subscription;

  sectionName = 'By Category x PoC Type';
  lstOption_IsUsed = {
    CHANNEL: true,
    SALES_DISTRICT_GROUP: true
  };
  lstOptionSelected = {
    CHANNEL: ['SMDD'],
    SALES_DISTRICT_GROUP   : ['CCBJI']
  };
  lstOptionApplied = {
    CHANNEL: ['SMDD'],
    SALES_DISTRICT_GROUP   : ['CCBJI']
  };
  filters = { CHANNEL: 'CHANNEL', AREA: 'CCBJI' };
  categories: any[] = [];
  chartData : any[]   = [];
  isLoading : boolean = false;
  isHasFirstLoad: boolean = false;
  lstAllOptionsData: any = {};
  filterQuery: Record<string, any> = {};
  prioritySortOrder = ['All Category', 'Cola SSD', 'Other SSD', 'Coffee', 'Juice', 'NS Tea', 'Black Tea', 'Sports', 'Energy', 'Water'];
  storeTMDisplay: string = '';

  constructor(private requestService: RequestService,
              private salesDateService: SalesDateService,
              private csvService: CsvService,
              private mixPanelTrackingService: MixPanelTrackingService) {}

  ngOnInit(): void {
    this.categories = PocHelper.getCategories();
    this.salesDateSubscription = this.salesDateService.getTraxSalesDate().subscribe((date: string[]) => {
      if (date && date.length > 0) {
        this.requestService.sendRequest(PocQueries.byCategoryFilter, {}, true).then((res) => {
          const data = JSON.parse(res) || {};

          this.lstAllOptionsData = {
            CHANNEL: data?.CHANNEL || [],
            SALES_DISTRICT_GROUP: data?.SALES_DISTRICT_GROUP || []
          };

          this._handleonFilterReady();
        });
      }
    });

    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasDateTypeChange  = changes['lstOptionSelectedDateType'] && this.lstOptionSelectedDateType?.length > 0;
    const hasSalesDateChange = changes['lstOptionSelectedSalesDate'] && this.lstOptionSelectedSalesDate?.length > 0;

    if (hasDateTypeChange || hasSalesDateChange) {
        this._onSearchData();
    }

    this.isHasFirstLoad = true;
  }

  ngOnDestroy(): void {
    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  // Update Chart
  public onUpdateChart(event: any) {
    const { lstOption_Selected: lstOptionSelected } = event;

    if (!lstOptionSelected) {
      return;
    }

    if (this.lstOptionSelected) {
      this.lstOptionSelected = { ...lstOptionSelected };
      this.lstOptionApplied  = { ...lstOptionSelected };
      this._onSearchData();
    }
  }

  private _onSearchData() {
    if (!this.lstOptionSelectedDateType?.length || !this.lstOptionSelectedSalesDate?.length) {
      return;
    }

    this.isLoading = true;

    try {
      const code = PocQueries.byCategory;
      this._getQueryObject();

      this.requestService.sendRequest(code, this.filterQuery, true)
        .then((res) => {
          const data = JSON.parse(res)?.ListAll || [];
          this.chartData = sortArrayByPriority(data, this.prioritySortOrder, 'CATEGORY_EN');
          this._getStoreTMDisplay();
          this._handleonReady();
        })
        .catch((error) => {
          console.error(`Request failed: ${error}`);
        })
        .finally(() => {
          this.isLoading = false;
        })
    } catch (error) {
      console.error(`Error in _onSearchData: ${error}`);
      this.isLoading = false;
    }
  }

  private _getQueryObject() {
    const queryObject = skuSetQueryString(this.lstOptionApplied) || {};
    const salesDate   = this.lstOptionSelectedSalesDate;
    const isSalesDateSelected = !isAllSelected(salesDate);
    const encodedSalesDate    = isSalesDateSelected ? encodeURIComponent(salesDate) : '';

    this.filterQuery = {
      ...queryObject,
      CacheKey: `SALES_YM=${encodedSalesDate}${queryObject['CacheKey']}`,
      Selected: {
        ...queryObject['Selected'],
        SALES_YM: isSalesDateSelected ? salesDate : [],
      }
    };
  }

  public getClassName(input: string) {
    let formattedString = String(input).toLowerCase();
    formattedString = formattedString.replace(/[^\w\s]/gi, '');
    formattedString = formattedString.replace(/\s+/g, '_');

    return formattedString;
  }

  public getLyValueDisplay(value: number, displayValue: string) {
    if (!value || !displayValue) {
      return displayValue;
    }

    const isNegative = value < 0;
    const isPositive = value > 0;

    if (isNegative && !displayValue.startsWith('-')) {
      return `-${displayValue}`;
    }

    if (isPositive && !displayValue.startsWith('+')) {
      return `+${displayValue}`;
    }

    return displayValue;
  }

  private _getStoreTMDisplay() {
    if (this.chartData && this.chartData.length) {
      const { STORE_TM_Display = '' } = this.chartData[0];
      this.storeTMDisplay = STORE_TM_Display;
    }
  }

  public isNegative(value: number) {
    const isNegative = value < 0;
    return isNegative;
  }

  public isHighLightCell(cellName: string) {
    const selectedArea = this.lstOptionApplied['CHANNEL'][0] || '';

    if (!selectedArea) {
      return false;
    }

    switch (selectedArea) {
      case 'SM':
        if (['EVENT', 'END', 'CHECKOUT', 'DELI', 'LIQUOR', 'BAKERY', 'SNACK'].includes(cellName)) {
          return true;
        }
        return false;

      case 'Drug':
        if (['EVENT', 'END', 'CHECKOUT', 'FOOD_SECTION', 'STORE_FRONT'].includes(cellName)) {
          return true;
        }
        return false;

      case 'Discounter':
        if (['EVENT', 'END', 'CHECKOUT', 'LIQUOR', 'FOOD_SECTION'].includes(cellName)) {
          return true;
        }
        return false;

      default:
        return false;
    }
  }

  private _handleonFilterReady() {
    let t = this;
    setTimeout(() => {
      t.filterReady.emit(true);
    });
  }

  private _handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  public downloadExcel() {
    const filterQuery = {
      CacheKey: `SALES_YM=${encodeURIComponent(this.lstOptionSelectedSalesDate)}`,
      Selected: {
        SALES_YM: this.lstOptionSelectedSalesDate
      }
    };

    this.csvService.getCsvUrl(PocQueries.byCategoryCSV, filterQuery, '')
      .subscribe((data) => {
        const csvUrl = data?.Csv_Url;

        if (csvUrl) {
          this._downloadExcel(csvUrl);
        }
      });
  }

  private _downloadExcel(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }
}