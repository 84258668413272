<div class="faceshare-component card">
  <div class="card-header">
    <h3 class="title">{{ sectionName }}</h3>
  </div>

  <app-photo-report-filter
    [filterModuleName]="'faceshare-by-account'"
    [filters]="filters"
    [lstOption_IsUsed]="lstOptionIsUsed"
    [lstAllOptionsData]="lstAllOptionsData"
    [lstOption_Selected]="lstOptionSelected"
    [sectionName]="sectionName"
    (onUpdateChart)="onUpdateChart($event)"
  ></app-photo-report-filter>

  <div *ngIf="isLoading" class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <h3 #observedElement class="title-hidden">{{ sectionName }}</h3>
  <!-- start Account Table -->
  <div class="row" *ngIf="!isLoading">
    <div class="col-lg-12 col-md-12">
      <div #scroll class="market-size">
        <div class="account-table__content">
          <table #screen>
            <ng-container *ngIf="chartData?.length">
              <thead>
                <tr class="row__first">
                  <th colspan="3" class="col" scope="col"></th>
                  <th colspan="5" class="all_category" scope="col">All Category</th>
                  <th colspan="5" class="ssd_total" scope="col">SSD</th>
                  <th colspan="5" class="cola_ssd" scope="col">Cola SSD</th>
                  <th colspan="5" class="other_ssd" scope="col">Other SSD</th>
                  <th colspan="5" class="coffee" scope="col">Coffee</th>
                  <th colspan="5" class="juice" scope="col">Juice</th>
                  <th colspan="5" class="ns_tea" scope="col">NS Tea</th>
                  <th colspan="5" class="black_tea" scope="col">Black Tea</th>
                  <th colspan="5" class="sports" scope="col">Sports</th>
                  <th colspan="5" class="energy" scope="col">Energy</th>
                  <th colspan="5" class="water" scope="col">Water</th>
                </tr>
                <tr class="row__second">
                  <th class="col account" scope="col">Account</th>
                  <th class="col segment" scope="col">Segment</th>
                  <th class="col vs-ly" scope="col">vs. LY (pts.)</th>
                  <th *ngFor="let category of categories; let i = index" class="col" scope="col">
                    <img [class]="category.value.toLowerCase()" [alt]="category.value" [src]="'assets/img/maker/' + category.image" [attr.loading]="'lazy'" />
                  </th>
                </tr>
              </thead>

              <tbody>
                <!--Loop here-->
                <ng-container *ngFor="let item of chartData; let i = index">
                  <ng-container *ngIf="item['CUSTOMER_GROUP_NAME'] === 'SM TTL'; else defaultItem">
                    <tr class="row__first">
                      <td class="col channel-ttl" colspan="2">{{lstOptionApplied['CHANNEL'][0]}} TTL</td>
                      <td class="col channel-ttl">
                        <span [ngClass]="{ 'text-red': item['SOVI_VSLY'] <= 0 }">{{ getAccValueDisplay(item['SOVI_VSLY'], item['SOVI_VSLY_Display']) }}</span>
                      </td>
                      <td *ngFor="let category of categories; let i = index" class="channel-ttl">{{ item[category.value + '_Display'] }}</td>
                    </tr>
                  </ng-container>

                  <ng-template #defaultItem>
                    <tr>
                      <td class="col account">
                        <ng-container *ngIf="item['CUSTOMER_GROUP_NAME'] as customerGroupName">
                          <div>
                            <span [ngbTooltip]="customerGroupName"
                                  [container]="'body'"
                                  placement="top-left">{{ customerGroupName }}</span>
                          </div>
                        </ng-container>
                      </td>
                      <td class="col segment">{{ item['ACCOUNT_SEGMENT'] }}</td>
                      <td class="col vs-ly">
                        <span [ngClass]="{ 'text-red': item['SOVI_VSLY'] < 0 }">{{ getAccValueDisplay(item['SOVI_VSLY'], item['SOVI_VSLY_Display']) }}</span>
                      </td>
                      <td *ngFor="let category of categories; let i = index" [ngClass]="{ 'highlight': accShouldHighlight(category.value, item) }">
                        {{ item[category.value + '_Display'] }}
                      </td>
                    </tr>
                  </ng-template>
                </ng-container>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="d-inline-flex download">
    <a #downloadLink style="display: none;"></a>
    <button type="button" class="btn btn-download-excel download-button-tracking" (click)="downloadExcel()">
      <span>Download Excel</span>
      <ng-icon name="IonDownloadOutline" size="16px" strokeWidth="46px"></ng-icon>
    </button>
  </div>
</div>
